.countdown-container {
  display: flex;
  justify-content: center;
  text-align: center;
}
.card {
  background-color: #345dee !important;
  display: inline-block;
  margin: 20px 5px;
  min-width: 100px;
  padding: 20px 0;
  width: 70px;
}

.countdown-value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 2rem;
  color: white;
}
.countdown-unit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  text-transform: capitalize;
  color: white;
}

@media screen and (max-width: 991px) {
  .card {
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 350px) {
}
