.pricing {
  padding-top: 10px;
}
.description {
  padding-top: 16px !important;
  max-width: 550px;
  margin: 0 auto !important;
}
.countdown_wrapper {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dark .item > div > div > div {
  border: 2px solid #1d2c6b;
}
.dark .item > div > div > div:hover {
  border: 2px solid #345dee;
}

.divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

@media screen and (max-width: 768px) {
  .description {
    padding-top: 16px !important;
    max-width: 420px;
    margin: 0 auto !important;
  }

  .logosRow {
    padding-top: 0;
  }
}
@media screen and (max-width: 450px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pricing {
    padding-top: 20px;
  }
}
