.cta {
  padding-bottom: 60px;
}
.textRow {
  padding-top: 38px;
}
.description {
  padding-top: 16px !important;
  max-width: 530px;
  margin: 0 auto !important;
}

.formRow {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .textRow {
    padding-top: 0;
  }
  .gridWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
