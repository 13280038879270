.col {
	padding: 0;
}
.row {
	justify-content: space-around;
	margin: 0;
}

.dark.logos svg path {
	fill: #ffffff !important;
}

@media screen and (max-width: 991px) {
	.col {
		margin-bottom: 20px;
		margin-right: 70px;
	}
	.col:nth-of-type(4),
	.col:nth-last-of-type(1) {
		margin-right: 0;
	}
}
@media screen and (max-width: 768px) {
	.col {
		min-width: 20%;
		max-width: 25%;
		margin-right: 0;
	}
	.col svg {
		width: 90%;
	}
}

@media screen and (max-width: 450px) {
	.col svg {
		width: 80%;
	}
	.col {
		max-width: 33.3%;
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-right: 0;
	}
	.col:nth-last-of-type(1) {
		margin-right: auto;
		margin-left: auto;
	}
}
