.swiperContainer {
    overflow: hidden;
    position: relative;
}
.swiperWrapper {

}
.swiperPagination {

}

.swiperSlide {
    text-align: center;
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    background-color: transparent !important;
    -webkit-transform: translate3d(0, 0, 0);
}


.swiperButtonNext,
.swiperButtonPrev {
    position: absolute;
    top: 50%;
    width: auto;
    height: auto;
    margin-top: -20px;
    z-index: 10;
    cursor: pointer;
    display: block;
    color: transparent;
    outline: 0px !important;
    -webkit-appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.swiperButtonNext {
    right: 0;
}

.dark .swiperButtonNext svg path,
.dark .swiperButtonPrev svg path {
    fill: #ffffff !important;
}

@media screen and (max-width: 1200px) {
    .swiperSlide  {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 70px;
        padding-right: 70px;
    }
}
@media screen and (max-width: 768px) {
    .swiperSlide  {
        font-size: 16px;
        line-height: 24px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .swiperButtonNext,
    .swiperButtonPrev {
        top: 15%;
        margin-top: 0;
    }
}
@media screen and (max-width: 450px) {
    .swiperSlide  {
        padding-left: 0;
        padding-right: 0;
    }
}