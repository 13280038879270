.wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .wrapper button[class*="Button"],
  .wrapper a[class*="Button"] {
    height: 50px !important;
  }
}
