.gridWrapper {
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  .gridWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
