.feature h1 {
  margin: 0;
  padding: 0;

  font-family: "Inter", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
  letter-spacing: -2.4px;
  color: #18214d;
}
.title {
  margin: 0;
  padding: 0;

  font-family: "Inter", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.55px;
  color: #18214d;
}

.wrapper .boldText {
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -2.4px;
  color: #18214d;
}

.feature p {
  padding-top: 14px !important;
}
@media screen and (max-width: 768px) {
  .description {
    padding-right: 0 !important;
    padding-top: 14px !important;
    max-width: 350px;
  }
}

@media screen and (max-width: 450px) {
  .title {
    margin: 0;
    padding: 0;

    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.5px;

    text-align: center !important;
  }
  .feature p {
    padding-top: 6px !important;
  }
  .description {
    text-align: center !important;
  }
}
