.coin {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 50px auto;
  transform-style: preserve-3d;
  -webkit-animation: rotate3d 8s linear infinite;
  animation: rotate3d 8s linear infinite;
  transition: all 0.3s;
}

.coin__front,
.coin__back {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #cea846;
}
.coin__front:after,
.coin__back:after {
  content: "";
  position: absolute;
  left: -150px;
  bottom: 100%;
  display: block;
  height: 200px;
  width: 600px;
  background: #fff;
  opacity: 0.3;
  -webkit-animation: shine linear 4s infinite;
  animation: shine linear 4s infinite;
}

.coin__front {
  /*background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/US_One_Cent_Obv.png/440px-US_One_Cent_Obv.png");*/
  background-image: url("./coin-front440.png");
  background-size: cover;
  transform: translateZ(10px);
}

.coin__back {
  /*background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/US_One_Cent_Rev.png/440px-US_One_Cent_Rev.png");*/
  background-image: url("./coin-back440.png");
  background-size: cover;
  transform: translateZ(-10px) rotateY(180deg);
}

.coin__edge div:nth-child(1) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #3c2714;
  transform: translateY(144.1125px) translateX(140px) rotateZ(94.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(2) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #776128;
  transform: translateY(144.1125px) translateX(140px) rotateZ(99deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(3) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #796127;
  transform: translateY(144.1125px) translateX(140px) rotateZ(103.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(4) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #836a2c;
  transform: translateY(144.1125px) translateX(140px) rotateZ(108deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(5) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #6d4625;
  transform: translateY(144.1125px) translateX(140px) rotateZ(112.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(6) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #784e29;
  transform: translateY(144.1125px) translateX(140px) rotateZ(117deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(7) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #84552c;
  transform: translateY(144.1125px) translateX(140px) rotateZ(121.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(8) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #8e5c30;
  transform: translateY(144.1125px) translateX(140px) rotateZ(126deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(9) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #996334;
  transform: translateY(144.1125px) translateX(140px) rotateZ(130.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(10) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #a36937;
  transform: translateY(144.1125px) translateX(140px) rotateZ(135deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(11) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ad703a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(139.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(12) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #b6763e;
  transform: translateY(144.1125px) translateX(140px) rotateZ(144deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(13) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #bf7c41;
  transform: translateY(144.1125px) translateX(140px) rotateZ(148.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(14) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c2824a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(153deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(15) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c58853;
  transform: translateY(144.1125px) translateX(140px) rotateZ(157.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(16) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c88e5b;
  transform: translateY(144.1125px) translateX(140px) rotateZ(162deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(17) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ca9363;
  transform: translateY(144.1125px) translateX(140px) rotateZ(166.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(18) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cd986a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(171deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(19) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cf9d71;
  transform: translateY(144.1125px) translateX(140px) rotateZ(175.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(20) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d1a278;
  transform: translateY(144.1125px) translateX(140px) rotateZ(180deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(21) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d4a67f;
  transform: translateY(144.1125px) translateX(140px) rotateZ(184.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(22) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d6ab85;
  transform: translateY(144.1125px) translateX(140px) rotateZ(189deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(23) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d8af8b;
  transform: translateY(144.1125px) translateX(140px) rotateZ(193.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(24) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #dab290;
  transform: translateY(144.1125px) translateX(140px) rotateZ(198deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(25) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #dbb695;
  transform: translateY(144.1125px) translateX(140px) rotateZ(202.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(26) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ddb99a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(207deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(27) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #debd9f;
  transform: translateY(144.1125px) translateX(140px) rotateZ(211.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(28) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e0bfa3;
  transform: translateY(144.1125px) translateX(140px) rotateZ(216deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(29) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e1c2a7;
  transform: translateY(144.1125px) translateX(140px) rotateZ(220.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(30) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e2c4aa;
  transform: translateY(144.1125px) translateX(140px) rotateZ(225deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(31) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e3c7ad;
  transform: translateY(144.1125px) translateX(140px) rotateZ(229.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(32) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e4c9b0;
  transform: translateY(144.1125px) translateX(140px) rotateZ(234deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(33) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e5cab3;
  transform: translateY(144.1125px) translateX(140px) rotateZ(238.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(34) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e6ccb5;
  transform: translateY(144.1125px) translateX(140px) rotateZ(243deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(35) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7cdb7;
  transform: translateY(144.1125px) translateX(140px) rotateZ(247.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(36) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7ceb8;
  transform: translateY(144.1125px) translateX(140px) rotateZ(252deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(37) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7cfb9;
  transform: translateY(144.1125px) translateX(140px) rotateZ(256.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(38) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e8d0ba;
  transform: translateY(144.1125px) translateX(140px) rotateZ(261deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(39) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cea846;
  transform: translateY(144.1125px) translateX(140px) rotateZ(265.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(40) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cea846;
  transform: translateY(144.1125px) translateX(140px) rotateZ(270deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(41) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cea846;
  transform: translateY(144.1125px) translateX(140px) rotateZ(274.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(42) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e8d0ba;
  transform: translateY(144.1125px) translateX(140px) rotateZ(279deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(43) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7cfb9;
  transform: translateY(144.1125px) translateX(140px) rotateZ(283.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(44) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7ceb8;
  transform: translateY(144.1125px) translateX(140px) rotateZ(288deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(45) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e7cdb7;
  transform: translateY(144.1125px) translateX(140px) rotateZ(292.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(46) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e6ccb5;
  transform: translateY(144.1125px) translateX(140px) rotateZ(297deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(47) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e5cab3;
  transform: translateY(144.1125px) translateX(140px) rotateZ(301.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(48) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e4c9b0;
  transform: translateY(144.1125px) translateX(140px) rotateZ(306deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(49) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e3c7ad;
  transform: translateY(144.1125px) translateX(140px) rotateZ(310.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(50) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e2c4aa;
  transform: translateY(144.1125px) translateX(140px) rotateZ(315deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(51) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e1c2a7;
  transform: translateY(144.1125px) translateX(140px) rotateZ(319.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(52) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #e0bfa3;
  transform: translateY(144.1125px) translateX(140px) rotateZ(324deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(53) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #debd9f;
  transform: translateY(144.1125px) translateX(140px) rotateZ(328.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(54) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ddb99a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(333deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(55) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #dbb695;
  transform: translateY(144.1125px) translateX(140px) rotateZ(337.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(56) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #dab290;
  transform: translateY(144.1125px) translateX(140px) rotateZ(342deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(57) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d8af8b;
  transform: translateY(144.1125px) translateX(140px) rotateZ(346.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(58) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d6ab85;
  transform: translateY(144.1125px) translateX(140px) rotateZ(351deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(59) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d4a67f;
  transform: translateY(144.1125px) translateX(140px) rotateZ(355.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(60) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #d1a278;
  transform: translateY(144.1125px) translateX(140px) rotateZ(360deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(61) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cf9d71;
  transform: translateY(144.1125px) translateX(140px) rotateZ(364.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(62) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #cd986a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(369deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(63) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ca9363;
  transform: translateY(144.1125px) translateX(140px) rotateZ(373.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(64) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c88e5b;
  transform: translateY(144.1125px) translateX(140px) rotateZ(378deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(65) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c58853;
  transform: translateY(144.1125px) translateX(140px) rotateZ(382.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(66) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #c2824a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(387deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(67) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #bf7c41;
  transform: translateY(144.1125px) translateX(140px) rotateZ(391.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(68) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #b6763e;
  transform: translateY(144.1125px) translateX(140px) rotateZ(396deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(69) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #ad703a;
  transform: translateY(144.1125px) translateX(140px) rotateZ(400.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(70) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #a36937;
  transform: translateY(144.1125px) translateX(140px) rotateZ(405deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(71) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #996334;
  transform: translateY(144.1125px) translateX(140px) rotateZ(409.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(72) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #8e5c30;
  transform: translateY(144.1125px) translateX(140px) rotateZ(414deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(73) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #84552c;
  transform: translateY(144.1125px) translateX(140px) rotateZ(418.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(74) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #784e29;
  transform: translateY(144.1125px) translateX(140px) rotateZ(423deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(75) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #6d4625;
  transform: translateY(144.1125px) translateX(140px) rotateZ(427.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(76) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #613f21;
  transform: translateY(144.1125px) translateX(140px) rotateZ(432deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(77) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #55371d;
  transform: translateY(144.1125px) translateX(140px) rotateZ(436.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(78) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #482f18;
  transform: translateY(144.1125px) translateX(140px) rotateZ(441deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(79) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #3c2714;
  transform: translateY(144.1125px) translateX(140px) rotateZ(445.5deg)
    translateX(150px) rotateY(90deg);
}
.coin__edge div:nth-child(80) {
  position: absolute;
  height: 11.775px;
  width: 20px;
  background: #2e1e10;
  transform: translateY(144.1125px) translateX(140px) rotateZ(450deg)
    translateX(150px) rotateY(90deg);
}

.coin__shadow {
  position: absolute;
  width: 300px;
  height: 20px;
  border-radius: 50%;
  background: #000;
  box-shadow: 0 0 100px 100px #000;
  opacity: 0.125;
  transform: rotateX(90deg) translateZ(-330px) scale(0.5);
}

@-webkit-keyframes rotate3d {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}
@-webkit-keyframes shine {
  0%,
  15% {
    transform: translateY(600px) rotate(-40deg);
  }
  50% {
    transform: translateY(-300px) rotate(-40deg);
  }
}
@keyframes shine {
  0%,
  15% {
    transform: translateY(600px) rotate(-40deg);
  }
  50% {
    transform: translateY(-300px) rotate(-40deg);
  }
}
