.header div[class*="navbar-nav"] a[class*="nav-link"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Inter", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #18214d;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header div[class*="navbar-nav"] a[class*="nav-link"]:hover {
  color: #2a3a87 !important;
}
.header .hiddenElements {
  display: none;
}

.header div[class*="socials"] a {
  margin-right: 10px;
}
.header div[class*="socials"] a:nth-last-child(1) {
  margin-right: 0;
}
.header div[class*="socials"] {
  padding-top: 60px;
}
.header div[class*="socials"] > div {
  padding-top: 12px;
}
.header div[class*="form"] {
  padding-top: 60px;
}
.header input[class*="form-control"] {
  height: 60px;
  padding: 0 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #3e4462;
  background: #eff2f6;
  border-radius: 8px;
  border: none;
  outline: 0px !important;
  -webkit-appearance: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.header input[class*="form-control"]::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #3e4462;
}
.header input[class*="form-control"]::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #3e4462;
}

.header span[class*="ant-input-affix-wrapper"] {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #3e4462;

  border: none;
  outline: 0px !important;
  -webkit-appearance: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
}
.header span[class*="ant-input-affix-wrapper"] > input[class*="ant-input"] {
  background: transparent;
}
.header span[class*="ant-input-search-icon"] {
  margin-left: 0.3em;
  padding: 0;
}
.header span[class*="ant-input-search-icon"] ::before {
  display: none;
}
.header .search {
  display: none;
}
.dark.header div[class*="navbar-nav"] a[class*="nav-link"]:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}
.dark.header
  span[class*="ant-input-affix-wrapper"]
  > input[class*="ant-input"] {
  color: rgba(255, 255, 255, 0.5) !important;
  mix-blend-mode: normal;
}
.dark span[class*="anticon"] svg path {
  fill: #ffffff !important;
}
.dark.header input[class*="form-control"] {
  color: rgba(255, 255, 255, 0.5);
  background: #162150;
}
.dark.header input[class*="form-control"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark button[class*="custom-toggler"] {
  border-color: rgb(52, 93, 238);
}
button[class*="custom-toggler"] {
  -webkit-appearance: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dark button[class*="custom-toggler"] span[class*="navbar-toggler-icon"] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(52, 93, 238)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.dark.header a[class*="navbar-brand"] svg path {
  fill: #ffffff !important;
}

@media screen and (max-width: 991px) {
  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
  }
  .header a[class*="navbar-brand"] {
    grid-column: 1/-1;
    grid-row: 1;
    text-align: start;
    margin-right: 0;
    padding: 0;
  }
  .header span[class*="ant-input-search-icon"]::before {
    display: none;
  }
  .header span[class*="ant-input-affix-wrapper"] {
    padding-left: 35px;
  }
  .header .search {
    display: flex;
    align-items: center;
    grid-row: 1;
    grid-column: 1/2;
  }
  .header button[class*="navbar-toggler"] {
    grid-column: 3/-1;
    grid-row: 1;
  }
  .header span[class*="ant-input-suffix"] {
    margin-left: 4px;
    position: absolute;
    left: 0;
    top: 8px;
  }
  .header .hiddenElements {
    display: block;
    padding-top: 40px;
  }
  .header div[class*="navbar-nav"] a[class*="nav-link"] {
    padding-left: 0 !important;
    padding-top: 40px;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.4px;

    color: #3e4462;
  }
  .header a[class*="Button"] {
    margin-top: 40px;
  }
}

@media screen and (max-width: 750px) {
  .header .wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 450px) {
  .header span[class*="ant-input-affix-wrapper"] {
    width: 100px !important;
  }
  .header span[class*="ant-input-affix-wrapper"] {
    padding-left: 35px;
  }
}

@media screen and (max-width: 375px) {
  .header span[class*="ant-input-affix-wrapper"] {
    width: 200px !important;
  }
  .header .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    row-gap: 15px;
  }
  .header a[class*="navbar-brand"] {
    grid-column: 1/3;
    grid-row: 1;
    text-align: left;
    margin-right: 0;
  }
  .header .search {
    display: block;
    grid-row: 2;
    grid-column: 1/2;
  }
  .header button[class*="navbar-toggler"] {
    grid-column: 3/-1;
    grid-row: 1;
  }
}
